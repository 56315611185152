<template>
  <el-form inline :model="selectModel" label-position="right">
    <el-form-item label="昵称">
      <el-input v-model="selectModel.nick" />
    </el-form-item>
    <el-form-item label="性别">
      <el-select v-model="selectModel.gender" placeholder="请选择" clearable>
        <el-option v-for="(a,b) in $store.state.gender" :key="b" :value="b" :label="a" />
      </el-select>
    </el-form-item>
    <el-form-item class="main-form-item-float-right">
      <el-button-group>
        <el-button type="primary" icon="el-icon-search" @click="queryTableData">查询</el-button>
      </el-button-group>
    </el-form-item>
  </el-form>

  <el-table
    :data="tableData"
    border
    stripe
    :default-sort="defaultSort"
    @sort-change="sort"
    style="width: 100%;">
    <el-table-column label="ID" prop="id" width="80px" align="center" sortable="custom" />
    <el-table-column label="创建时间" prop="createTime" min-width="180px" />
    <el-table-column label="头像" prop="avatar" min-width="80px">
      <template #default="scope">
        <el-image
          style="width: 60px; height: 60px;"
          :src="scope.row.avatar"
          :fit="fit" />
      </template>
    </el-table-column>
    <el-table-column label="昵称" prop="nick" min-width="200px" />
    <el-table-column label="性别" prop="gender" min-width="50px">
      <template #default="scope">
        {{ $store.getters.columnIdToVal('gender', scope) }}
      </template>
    </el-table-column>
    <el-table-column label="国家" prop="country" min-width="100px" />
    <el-table-column label="省" prop="province" min-width="100px" />
    <el-table-column label="市" prop="city" min-width="100px" />
    <el-table-column
      fixed="right"
      label="操作"
      align="center"
      width="120">
      <template #default="scope">
        <el-dropdown>
          <el-button size="mini" type="primary">
            设为<i class="el-icon-arrow-down el-icon--right" />
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="addUserRole(scope.row.id, 1)">安装人员</el-dropdown-item>
              <el-dropdown-item @click="addUserRole(scope.row.id, 2)">测试人员</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <!-- <el-button @click="showDialogForm({data: scope.row, readonly: true})" type="text" size="small">查看</el-button>
        <el-button @click="showDialogForm({data: scope.row})" type="text" size="small">编辑</el-button>
        <el-button @click="deleteAciton(scope.row)" type="text" size="small">删除</el-button> -->
      </template>
    </el-table-column>
  </el-table>
  <div style="margin-top: 10px; text-align: right;">
    <el-pagination
      layout="prev, pager, next,jumper"
      :total="total"
      @current-change="applyCurrentPage" />
  </div>
</template>

<script>
import { handleCatchStatus } from '../../api/handler';

export default {
  data() {
    return {
      selectModel: {},
      selectSort: null,
      defaultSort: {prop: 'id', order: 'descending'},
      tableData: [],
      total: 0,
      current: 1,
      formInline: true,
      dialog: {
        visibled: false,
        created: false,
        readonly: false,
        title: '',
        form: {},
        submit: () => true
      },
      roles: []
    };
  },
  created() {
    this.applySortSelect(this.defaultSort);
    this.queryTableData();
  },
  mounted() {
  },
  methods: {
    queryTableData() {
      this.selectModel.sort = this.selectSort;
      this.selectModel.page = this.current - 1;
      this.$api.getUserPage(this.selectModel)
        .then(data => {
          this.tableData = data.content;
          this.total = data.totalElements;

          this.tableData.forEach(item => {
            if (item.id === 1) {
              item.avatar = 'nooo';
              item.nick = 'YamRlo';
            }
          });
        })
        .catch(handleCatchStatus);
    },
    sort({ prop, order }) {
      this.applySortSelect({ prop, order });
      this.queryTableData();
    },
    applySortSelect({ prop, order }) {
      if (order) {
        this.selectSort = `${prop},${order === 'ascending' ? 'ASC' : 'DESC'}`;
        return;
      }
      this.selectSort = null;
    },
    applyCurrentPage(current) {
      this.current = current;
      this.queryTableData();
    },
    addUserRole(userId, role) {
      this.$confirm(`你确定要将该用户设为 ${this.$store.state.userRole[role]} ?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => this.$api.createUserRole({userId, role}))
        .then(() => {
          this.$message.success('设置成功!');
          this.queryTableData();
        })
        .catch(() => this.$message.info('已取消设置'));
    }
  }
};
</script>
